/*!

 =========================================================
 * Material Dashboard PRO Angular - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-angular2
 * Copyright 2018 Creative Tim (https://www.creative-tim.com)

 * Designed by www.invisionapp.com Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
@use '@angular/material' as mat;
@import '@angular/material/prebuilt-themes/indigo-pink.css';

@import 'core/variables';
@import 'core/mixins';
@import 'node_modules/bootstrap/scss/bootstrap';
@import 'core/reboot';

// Core Components
@import 'core/layout';
@import 'core/buttons';
@import 'core/checkboxes';
@import 'dashboard/cards';
@import 'core/custom-forms';
@import 'core/switches';
@import 'core/example-pages';
@import 'core/radios';
@import 'core/carousel';
@import 'dashboard/forms';
@import 'core/input-group';
@import 'core/list-group';
@import 'core/nav';
@import 'core/images';
@import 'dashboard/navbar';
@import 'dashboard/alerts';
@import 'core/pagination';
@import 'core/pills';
@import 'core/info-areas';
@import 'core/type';
@import 'core/tabs';
@import 'core/footers';
@import 'core/tooltip';
@import 'core/popover';
@import 'core/modal';
@import 'core/dropdown';
@import 'core/headers';
@import 'core/drawer';
@import 'core/progress';
@import 'core/togglebutton';
@import 'core/ripples';
@import 'dashboard/rtl';
@import 'dashboard/sidebar-and-main-panel';
@import 'dashboard/timeline';
@import 'dashboard/fixed-plugin';
@import 'dashboard/tables';
@import 'dashboard/misc';
@import 'dashboard/pages';

// Components for PRO
// @import "dashboard/social-buttons";
@import 'dashboard/fileupload';

//plugin css
@import 'plugins/animate';
@import 'plugins/sweetalert2';
@import 'plugins/plugin-datetime-picker';
@import 'plugins/wizard-card';
// @import "plugins/perfect-scrollbar";

@import 'dashboard/responsive';

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`

//    `@include mat.all-legacy-component-typographies();`
//  @include mat.legacy-core();

@include mat.all-component-typographies();
@include mat.core();

$wise-pelican-purple: (
    50: #e9e4e9,
    100: #c8bbc8,
    200: #a38da3,
    300: #7e5f7e,
    400: #633d62,
    500: #471b46,
    600: #40183f,
    700: #371437,
    800: #2f102f,
    900: #200820,
    A100: #c5cae9,
    A200: #c5cae9,
    A400: #c5cae9,
    A700: #c5cae9,
    contrast: (
        50: rgba(black, 0.87),
        100: rgba(black, 0.87),
        200: rgba(black, 0.87),
        300: white,
        400: white,
        500: rgba(white, 0.87),
        600: rgba(white, 0.87),
        700: rgba(white, 0.87),
        800: rgba(white, 0.87),
        900: rgba(white, 0.87),
        A100: rgba(black, 0.87),
        A200: white,
        A400: white,
        A700: rgba(white, 0.87),
    ),
);

$primary: mat.m2-define-palette($wise-pelican-purple, 500, A100, A400);
$accent: mat.m2-define-palette(mat.$m2-light-blue-palette, 500, A100, A400);
$warn: mat.m2-define-palette(mat.$m2-red-palette, 600);

$theme: mat.m2-define-light-theme(
    (
        color: (
            primary: $primary,
            accent: $accent,
            warn: $warn,
        ),
        typography: mat.m2-define-typography-config(),
        density: 0,
    )
);

// @include mat.all-legacy-component-themes($theme);
@include mat.all-component-themes($theme);

.mat-checkbox-checked.mat-primary .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background {
    background-color: #471b46;
}

.mat-step-header .mat-step-icon,
.mat-step-header .mat-step-label {
    opacity: 0.3;
}

.mat-step-header .mat-step-icon {
    background-color: #471b46;
}

.mat-step-header .mat-step-label.mat-step-label-selected,
.mat-step-header .mat-step-icon.mat-step-icon-selected {
    opacity: 1;
}

mat-progress-spinner circle,
mat-spinner circle {
    stroke: #471b46 !important;
}

mat-progress-spinner.mat-accent circle,
mat-spinner.mat-accent circle {
    stroke: white !important;
}

.ng-invalid.mat-focused .mat-form-field-label {
    color: #9c27b0;
}

.ps__thumb-x {
    background-color: #471b46 !important;
    height: 10px !important;
}

mat-form-field.white-background {
    .mat-form-field-wrapper {
        background-color: white;
        padding: 6px 4px 4px 10px;
        border-radius: 4px;
    }

    .mat-form-field-underline {
        height: 0;
    }

    .mat-form-field-ripple {
        height: 0;
    }

    &.not-select {
        .mat-form-field-infix {
            padding-top: 0;
        }

        .mat-form-field-suffix {
            margin-top: 10px;
        }
    }
}

// mat-chip {
//     background-color: #471b46 !important;
//     color: white !important;

//     .mat-chip-remove {
//         color: white !important;
//         opacity: 1 !important;
//         -webkit-appearance: none;
//         -moz-appearance: none;
//         appearance: none;
//     }
// }

.mat-dialog-title {
    padding: 24px 24px 0;
}

.custom-select-panel.mat-mdc-select-panel {
    margin-top: 8px;
    box-shadow: -20px 20px 40px -4px rgba(145, 158, 171, 0.24);
    padding: unset;
    border-radius: 12px;
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;

    &::-webkit-scrollbar {
        width: 8px !important;
        background-color: #f5f5f5 !important;
    }

    &::-webkit-scrollbar-track {
        border-radius: 4px !important;
        background-color: #f5f5f5 !important;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 4px !important;
        background-color: #cccccc !important;

        &:hover {
            background-color: #aaaaaa !important;
        }
    }
}

@import 'custom/_custom.scss';
